import 'alpinejs';
import sal from 'sal.js';
sal();
//console.log("JS LOADED")
import simpleParallax from 'simple-parallax-js';
var images = document.getElementsByClassName('parallax');
new simpleParallax(images, {
	delay: 1,
	transition: 'cubic-bezier(0.1,0,0,1)',
   customWrapper: '.parallax-wrapper',
   scale: 1.2,
});
import GLightbox from 'glightbox';//Lightbox
const lightbox = GLightbox({
  touchNavigation: true,
  selector: 'data-lightbox',
  videosWidth: '80vw'
});
console.log("JS LOADED")
//var Flickity = require('flickity');

import 'mmenu-js';
// Mmmenu Configuration
document.addEventListener(
  "DOMContentLoaded", () => {
    const menu = new Mmenu("#mobile-menu", {
      extensions: ["position-right", "pagedim-black", "shadow-page", "theme-dark"],
      navbars: [{
        "position": "top",
        "content": [
          "<div style='padding: 30px; max-height: 300px; width: 60%; margin: 0 auto'><img style='max-width: 100%; max-height: 100%' src='/assets/logo-primary.svg'> </div>"
        ]
      }]
    });

  }
);